@import './menu.scss';
@import './colors.scss';
@import './footer.scss';
@import './imageStyling.scss';
@import './header.scss';
@import './carousel.scss';
@import './projects.scss';
@import './artgallery.scss';
@import './progressbar.scss';
@import './lazyloading.scss';
@import './buttons.scss';

:root {
  color-scheme: light dark;
  color: #2a2c31;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}
@supports (-webkit-appearance: none) {
  object {
    background-color: transparent;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%;
  background-color: $platinum;
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  font-family: 'Satoshi', sans-serif;
}
img {
  max-width: 100%; /* Set the maximum width to 100% of the container's width */
  height: auto; /* Maintain the aspect ratio */
  object-fit: cover;
}

section {
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin-bottom: 3rem;
}

p {
  max-width: 520px;
  font-family: 'Neue Regrade', sans-serif !important;
  color: var(--space);
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

a:hover {
  text-decoration: none;
}

li {
  font-family: 'Neue Regrade', sans-serif !important;
  list-style: none;
}

h3 {
  font-size: 28px;
  font-weight: bolder;
}

@media (max-width: 768px) {
  .btn--prm {
    width: 12rem;
  }
  .container {
    align-items: center; /* Change justify-content to center */
    // text-align: center;
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .overlay {
    font-size: 2rem !important;
  }

  .project-overlay {
    font-size: 2.5rem !important;
    font-weight: 600;
  }

  .slide-overlay {
    font-size: 1.5rem;
  }

  #carousel {
    height: 70vh;
  }

  section {
    align-items: center;
    text-align: center;
  }

  .btn--secondary {
    width: 8rem;
  }

  .bottom-nav {
    padding: 0rem 0rem !important;
    gap: 1rem;
  }
}

// .section--right {
//   display: flex;
//   flex-direction: column;
//   align-items: flex-end;
//   justify-content: flex-end;
//   text-align: right;
// }

.section--center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.bottom-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  padding: 0rem 12rem;
}

a {
  color: #2a2c31;
  text-decoration: none;
  transition: text-decoration 1.5s ease-in-out;
}

// a:hover {
//   text-decoration: underline;
// }

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-top: 2.5rem;
}
/* UI STYLES */

.sticky {
  position: fixed !important;
}

.hidden {
  display: none !important;
}

.no--scroll {
  overflow: hidden;
}

#heading {
  display: flex;
  gap: 0.5rem;
}

#freesol--spinning {
  width: 120px;
  height: 120px;
}

// .gallery {
//   display: flex;
//   flex-direction: column;
//   gap: 0.5rem;
// }

.spacer {
  margin-top: 2.5rem;
}

.spacer--small {
  margin-top: 1.625rem;
}

#hero--wrapper {
  position: relative;
  width: auto;
  height: 95vh;
  overflow: hidden;
}

.hero--overlay {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: grey;
}

.hero--label {
  z-index: 10;
  font-size: 7rem;
  font-weight: 900;
  color: $platinum;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100%;
  mix-blend-mode: exclusion;
}

#top--layer--content {
  display: flex;
  height: 90vh;
  flex-direction: column;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 1rem;
  justify-content: end;
  align-items: center;
  z-index: 10;
}
#hero--video {
  width: 100%; /* Make the video full-width within the wrapper */
  height: 100%; /* Make the video full-height within the wrapper */
  object-fit: cover; /* Ensure the video covers the entire wrapper */
  position: absolute; /* Position the video absolutely within the wrapper */
  top: 0; /* Align to the top of the wrapper */
  left: 0; /* Align to the left of the wrapper */
  z-index: 1;
}

.cover-video {
  position: absolute;
  object-fit: cover; /* Ensure the video covers the entire wrapper */
  z-index: -1;
}

.cover-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hero--top {
  position: relative;
  z-index: 2;
}

.image--preview:active {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.project--layer--content {
  display: flex;
  height: 80vh;
  flex-direction: column;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 80px;
  justify-content: flex-end;
  align-items: flex-start;
}

.project--name {
  color: #eee;
  z-index: 10;
}

#privacy--stuff {
  padding-top: 2rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  color: $platinum;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.paragraph {
  margin-top: 1.25rem;
  letter-spacing: 0.05rem;
}

.more--projects {
  margin-top: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlay {
  font-size: 3rem;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  mix-blend-mode: exclusion;
  padding-bottom: 4rem;
  transition: all 500ms;
}

.overlay:hover {
  mix-blend-mode: normal;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: $platinum;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the alpha value for desired opacity */
}

/* UI BUTTONS */

.contact--content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
}

#mail-icon {
  position: relative;
  left: -10px;
  transition: left 0.5s ease-in-out;
}

#mail-icon:hover {
  left: 0;
}

@media screen and (max-width: 600px) {
  /* FONTS STYLES */

  .s--regular {
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 0.8rem;
  }

  .m--bold {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2rem;
  }

  .l--regular {
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1.875rem;
  }

  .l--bold {
    font-size: 1.875rem; /* 30px */
    font-weight: 700;
    line-height: 1.875rem;
  }

  .xl--bold {
    font-size: 2.2rem; /* 48px */
    font-style: normal;
    font-weight: 700;
    line-height: 2.2rem; /* 37.5% */
  }

  .xxl--bold {
    font-size: 3rem; /* 48px */
    font-style: normal;
    font-weight: 700;
    line-height: 3rem; /* 37.5% */
  }

  .subheading {
    font-size: 3rem;
  }

  .hero--label {
    font-size: 3rem;
  }
}

/* Medium screens */
@media screen and (min-width: 600px) {
  .subheading {
    font-size: 3rem;
  }

  .showcase--start {
    display: flex;
    // height: 50rem;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    gap: 1rem;
    flex-shrink: 0;
    margin-bottom: 3.75rem;
  }

  // #showcases {
  //   justify-content: flex-end;
  // }

  .l--regular {
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.2rem;
  }

  .l--bold {
    font-size: 10rem; /* 30px */
    font-weight: 700;
    line-height: 1.875rem;
  }

  .xl--bold {
    font-size: 4rem; /* 48px */
    font-style: normal;
    font-weight: 700;
    line-height: 4rem; /* 37.5% */
  }
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-auto-rows: 30vh;
  place-items: center;
  gap: 0.5rem;
  margin: 1.5rem 0rem;
}

.image-portrait {
  // max-width: 400px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.image-landscape {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.image-wide {
  grid-column: span / auto;
}

.text-wide {
  grid-column: span 3 / auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  width: 100%;
}

.image-tall {
  grid-row: span 2 / auto;
}

@media screen and (min-width: 1000px) {
  .image-wide {
    grid-column: span 3 / auto;
    grid-row: span 3;
  }

  .gallery {
    grid-template-columns: repeat(3, minmax(350px, 1fr));
    grid-row: 60vh;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Satoshi', sans-serif;
  font-size: 4rem;
  color: $platinum;
  box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem,
    rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
  width: 100%;
  // max-width: 80vw;
  transition: all 500ms;
  overflow: hidden;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.single-image {
  margin-top: 1.5rem;
  width: 80%;
  height: 60vh !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.portraits {
  display: flex;
  gap: 1rem;
}

.portrait {
  margin-top: 1.5rem;
  // margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: #fff;
  box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem,
    rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
  height: 70vh;
  width: 30vw;
  transition: all 500ms;
  overflow: hidden;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.projects {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100vw;
}

.project-preview {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 33vh;
}

.project-overlay {
  text-decoration: none !important;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 4rem;
  transition: all 500ms;
  font-size: 5rem;
  color: white;
}

.project-overlay:hover {
  mix-blend-mode: normal;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: $platinum;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the alpha value for desired opacity */
}
// SPLIDE

.splide__slide img {
  height: 100%;
  width: auto;
  object-fit: cover;
}
